import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Products = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    getlist();
  }, []);

  const getlist = () => {
    fetch("http://localhost:8080/Products")
      .then((res) => res.json())
      .then((json) => setList(json));
  };
  console.log(list);

  return (
    <div className="App">
      madhuri
      {list.map((e, i) => (
        <div key={i}>
          <h4>
            {e.name}
            <br /> Price : {e.price}
          </h4>
          <button>
            <Link to={`/products/${e.id}`}>More Info</Link>
          </button>
        </div>
      ))}
    </div>
  );
};

export default Products;
