import { Link, useParams } from "react-router-dom";
// import { useEffect, useState } from "react/cjs/react.development";
import { useEffect, useState } from "react";

const Prodetail = () => {
  const { id } = useParams();
  const [list, setList] = useState([]);

  useEffect(() => {
    getlist();
  }, []);

  const getlist = () => {
    fetch(`http://localhost:8080/Products/${id}`)
      .then((res) => res.json())
      .then((json) => setList(json));
  };

  return (
    <div>
      <h1>
        {list.name}
        <br />
        {list.price}
      </h1>
      <h3 style={{ width: "70%", marginLeft: "15%" }}>{list.description}</h3>
      <button>
        <Link to={`/products`}>Back to shopping</Link>
      </button>
    </div>
  );
};

export default Prodetail;
