import { Route, Routes } from "react-router-dom";
import Home from "./componrnts/Home";
import "./App.css";
import Navbar from "./componrnts/Navbar";
import Products from "./componrnts/Products";
import ProductsDet from "./componrnts/Prodetail";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="products" element={<Products />}></Route>
        <Route path="products/:id" element={<ProductsDet />}></Route>
      </Routes>
    </div>
  );
}

export default App;
